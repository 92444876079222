<template>
  <div class="light-course-module-teaser" :class="{
    locked: module.locked,
    finished: module.progress >= 100
  }">
    <b-row class="align-items-center">
      <b-col cols="auto" class="f-0">
        <div class="module-circle"></div>
      </b-col>
      <b-col col>
        <div class="pl-1">
        <div class="content">
          {{ module.title }}
        </div>
        <div>
          <span class="progress-badge finished" v-if="module.progress >= 100"><TickSvg/>{{ $t('finished') }}</span>
          <span class="progress-badge closed" v-else-if="module.locked"><LockSvg/>{{ $t('closed') }}</span>
          <span class="progress-badge new" v-else>NEW</span>
        </div>
        </div>
      </b-col>
      <b-col md="auto" cols="12" class="mt-md-0 mt-3 text-right">
        <b-button class="btn-themed"
                  :disabled="module.locked"
                  :class="{ 'btn-white': module.progress >= 'finished' }"
                  :to="{ name: 'StudentLightCourseLessons', params: { courseId: module.light_course_id, moduleId: module.id } }">
          {{ module.progress >= 100 ? $t('open') : $t('start') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TickSvg from '@/assets/svg/tick.svg'
import LockSvg from '@/assets/svg/lock.svg'

export default {
  name: "LightCourseModuleTeaser",
  components: {
    TickSvg,
    LockSvg
  },
  props: {
    module: {
      type: Object,
      required: true
    }
  },
}
</script>

<style scoped lang="scss">
.light-course-module-teaser {
  .module-circle {
    border-radius: 100%;
    width: 64px;
    height: 64px;
    display: inline-block;
    background-color: #DFE321;
  }
  .btn-themed {
    min-width: 132px;
  }
  .content {
    //padding-left: 6px;
    font-weight: 600;
  }
  &.finished {
    .module-circle {
      background-color: #27AE60;
    }
  }
  &.locked {
    .module-circle {
      background-color: #E6EAF2;
    }
    .content {
      color: #828282;
    }
  }
  .progress-badge {
    border-radius: 7px;
    padding: 2px 8px;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    color: #fff;
    margin-top: 6px;
    &.new {
      background: #56CCF2;
    }
    &.finished {
      background: #27AE60;
    }
    &.closed {
      color: #B8BAC1;
      border: 1px solid #DADCE2;
    }
    svg {
      margin-right: 4px;
    }
  }
}
@media(max-width: 768px) {
  .light-course-module-teaser {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 12px;
    .module-circle {
      width: 40px;
      height: 40px;
    }
  }
}
</style>
