<template>
  <section>
    <div class="spacecard border-0" v-if="course">
      <b-row class="align-items-center">
        <b-col cols="auto">
          <b-button class="btn-themed back-btn-squared btn-white" :to="{ name: 'StudentLightCourses' }">
            <BackSvg/>
          </b-button>
        </b-col>
        <b-col>
          <h2>
            {{ course.title }}
          </h2>
        </b-col>
        <b-col cols="auto">
          <ProgressPie size="small"
                       :progress="course.progress"/>
        </b-col>
      </b-row>
      <hr/>
      <div>
        <LightCourseModuleTeaser v-for="(m, mind) in modules"
                           :key="`module-${mind}`"
                           class="mb-4"
                           :module="m"/>
      </div>
    </div>
  </section>
</template>

<script>
import BackSvg from '@/assets/svg/back_3.svg'

import { StudentPageService } from "../../../../services/api.service"
import ProgressPie from "../../../parts/general/ProgressPie.vue"
import LightCourseModuleTeaser from "../../../parts/courses/LightCourseModuleTeaser.vue"

export default {
  name: "StudentLightCourseModules",
  components: {
    LightCourseModuleTeaser,
    ProgressPie,
    BackSvg
  },
  metaInfo() {
    return {
      title: this.$t('modules'),
    }
  },
  data() {
    return {
      modules: [],
      course: null,
    }
  },
  computed: {
    courseId() {
      return this.$route.params.courseId
    }
  },
  methods: {
    async loadCourseModules() {
      const res = await StudentPageService.getLightCourseModules(this.courseId)
      this.course = res?.data?.data?.course || null
      this.modules = res?.data?.data?.modules || []
    }
  },
  mounted() {
    this.loadCourseModules()
  }
}
</script>

<style scoped lang="scss">
h2 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 700;
  margin-left: 6px;
}
@media(max-width: 768px) {
  h2 {
    font-size: 16px;
  }
}
</style>
